import { render, staticRenderFns } from "./CenteredManagementNavigation.vue?vue&type=template&id=6f1eb8e8&scoped=true&"
import script from "./CenteredManagementNavigation.vue?vue&type=script&lang=js&"
export * from "./CenteredManagementNavigation.vue?vue&type=script&lang=js&"
import style0 from "./CenteredManagementNavigation.vue?vue&type=style&index=0&id=6f1eb8e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f1eb8e8",
  null
  
)

export default component.exports