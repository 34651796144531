import { render, staticRenderFns } from "./ManageEvents.vue?vue&type=template&id=c60f7ffc&scoped=true&"
import script from "./ManageEvents.vue?vue&type=script&lang=js&"
export * from "./ManageEvents.vue?vue&type=script&lang=js&"
import style0 from "./ManageEvents.vue?vue&type=style&index=0&id=c60f7ffc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c60f7ffc",
  null
  
)

export default component.exports